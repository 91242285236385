import { Ability, AbilityBuilder } from "@casl/ability"

export const getAbilities = user => {
  const { can, rules } = new AbilityBuilder(Ability)
  can('view', 'auth')

  const roles = user?.roles ? user?.roles : user?.userToken?.roles

  roles?.forEach(role => {
    can('view', role)
  });

  const perfil = user?.perfil ? user?.perfil : user?.userToken?.perfil

  if (perfil === 'SuperAdmin') {
    can('view', 'usuarios')
    can('view', 'superadmin')
  } 

  return rules
}

