// ** Reducers Imports
import auth from './authentication'
import navbar from './navbar'
import layout from './layout'
import loader from './loader'

const rootReducer = {
  auth,
  navbar,
  layout,
  loader
}

export default rootReducer
