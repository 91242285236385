// ** React Imports
import { useState, createContext } from 'react'

// ** Create Context
const GlobalLoader = createContext()

// ** Componentes
import SpinnerComponent from '@components/spinner/Fallback-spinner'

const GlobalLoaderContext = ({ children }) => {
  // ** State
  const [is_loading, setIsLoading] = useState(false)

  return (
    <GlobalLoader.Provider value={{ is_loading, setIsLoading }}>
        {is_loading && <SpinnerComponent is_full_screen/>}
        <div>
          {children}
        </div>
    </GlobalLoader.Provider>
  ) 
}

export { GlobalLoader, GlobalLoaderContext }
