// ** Logo
import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = props => {
  const { is_full_screen } = props

  return (
    <div className={`fallback-spinner${is_full_screen ? ' app-loader' : ''}`}>
      {/* <img className='fallback-logo' src={logo} alt='logo' /> */}
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
